<template>
    <div id="draw">
        <div id="msg" v-html="msg"></div>
        <canvas id="canvas" width="300" height="300" ref="canvas"></canvas>
        <div id="msg2" v-html="msg2"></div>
        <div id="tools" class="box" v-show="toolsVisible">
            <div class="d-flex flex-row">
                <div v-for="n in 10" :key="n" :id="'c' + n" class="color" :data-value="colors[n]" @click="changeColor(colors[n])" :style="{ backgroundColor: colors[n] }">
                </div>
            </div>
            <div class="d-flex flex-row align-center mt-2">
                <div v-for="(size, index) in penSizes" :key="index" class="pen-container" @click="changePenSize(size)">
                    <div class="pen" :class="{ 'pen-selected': size === myWidth }" :style="{ width: size + 'px', height: size + 'px' }"></div>
                </div>
                <v-btn @click="clearCanvas" class="ml-2">清空画布</v-btn>
            </div>
        </div>
        <div id="textBox" class="box" v-show="textBoxVisible">
            <v-text-field id="text" name="text" placeholder="输入要猜测的词语" ref="text" v-model="text"></v-text-field>
            <v-btn id="guess" @click="guess">确定</v-btn>
        </div>
        <div id="players" v-html="players"></div>
        <v-btn id="startButton" @click="startGame" v-show="startButtonVisible" class="mt-2">开始游戏</v-btn>
        <v-btn id="reviewPics" @click="reviewPics" v-show="reviewPicsVisible" class="mt-2">欣赏画作</v-btn>
        <div id="review" v-show="showReview" ref="review"></div>
    </div>
</template>
  
<script>
import { baseURL } from '@/utils/config.js';

import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';

export default {
    name: "Empty",
    data() {
        return {
            pics: [],
            myWidth: 5,
            penSizes: [5, 10, 20],
            colors: ['#000000', '#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#00FFFF', '#FF00FF', '#C0C0C0', '#808080', '#800000', '#808000', '#008000', '#800080', '#008080', '#000080'],
            myColor: '#000',
            myWidth: 3,
            allowDraw: false,
            canvas: null,
            context: null,
            socket: null,
            stompClient: null,
            msg: '', // 系统广播
            msg2: '', // 系统广播2
            players: '', // 玩家列表
            toolsVisible: false, // 工具栏是否可见
            startButtonVisible: false, // 开始游戏按钮是否可见
            text: '', // 猜词文本框
            textBoxVisible: false, // 文本框是否可见
            reviewPicsVisible: false, // 查看画作按钮是否可见
            showReview: false, // 是否显示画作
        };
    },
    computed: {
        name() {
            return this.$store.state.username;
        }
    },
    created() {
        if (this.name === null || this.name === '' || this.name === 'null') {
            this.$router.push('/');
        }

        const socket = new SockJS(baseURL + `/endpointDraw?name=${this.name}`);
        this.stompClient = Stomp.over(socket);
        this.connect(this.name);
    },
    mounted() {
        this.$nextTick(() => {
            this.canvas = this.$refs.canvas;
            this.context = this.canvas.getContext('2d');
            this.doClear();
            this.setupCanvas(); // 在组件挂载后设置画板
        });
    },
    methods: {
        connect(name) {
            this.stompClient.connect({}, frame => {
                // 订阅广播的【主题】消息
                this.stompClient.subscribe('/topic/draw', response => {
                    var msg = JSON.parse(response.body)
                    if (msg.type == 'drawing') { // 某人作画
                        this.context.lineWidth = msg.width // 设置画笔宽度
                        this.context.strokeStyle = msg.color // 设置线条颜色
                        this.context.fillStyle = msg.color // 设置填充颜色
                        this.context.beginPath() // 开始画线
                        this.context.moveTo(msg.startX, msg.startY) // 起始坐标
                        this.context.lineTo(msg.endX, msg.endY) // 结束坐标
                        this.context.closePath() // 结束画线
                        this.context.stroke() // 画出线条
                        this.context.save() // 保存状态
                    } else if (msg.type == 'msg') { // 系统广播
                        this.msg = msg.text
                    } else if (msg.type == 'msg2') { // 系统广播2
                        this.msg2 = msg.text
                    } else if (msg.type == 'players') { // 玩家列表
                        this.players = msg.text
                        // 显示查看画作按钮
                        if (msg.text.indexOf("组队中") != -1 && this.pics.length > 0) {
                            this.reviewPicsVisible = true
                        } else {
                            this.reviewPicsVisible = false
                        }
                    } else if (msg.type == 'undo') {
                        this.context.restore();
                    } else if (msg.type == 'clear') {
                        this.doClear();
                    } else if (msg.type == 'saveImage') { // 服务器指示浏览器保存画作
                        var myImage = this.canvas.toDataURL("image/png");
                        this.pics.push(myImage)
                        console.log("画作已保存")
                    }
                });
                // 订阅自己的【队列】消息
                this.stompClient.subscribe('/user/queue/drawUserInfo', response => {
                    var msg = JSON.parse(response.body)
                    if (msg.type == 'startButton') { // 显示【开始游戏】按钮
                        this.toolsVisible = false
                        this.startButtonVisible = true
                        this.textBoxVisible = false
                    } else if (msg.type == 'allowDraw') { // 允许当前用户作画
                        this.allowDraw = true
                        this.msg = msg.info
                        this.toolsVisible = true
                        this.startButtonVisible = false
                        this.textBoxVisible = false
                    } else if (msg.type == 'allowGuess') { // 允许当前用户猜词
                        this.allowDraw = false
                        this.toolsVisible = false
                        this.startButtonVisible = false
                        this.textBoxVisible = true
                    } else if (msg.type == 'disallowGuess') { // 已经猜对的人不让再猜了，但是不清空画布
                        this.allowDraw = false
                        this.toolsVisible = false
                        this.startButtonVisible = false
                        this.textBoxVisible = false
                    } else if (msg.type == 'disallowAny') { // 都不允许
                        this.allowDraw = false
                        this.toolsVisible = false
                        this.startButtonVisible = false
                        this.textBoxVisible = false
                    } else if (msg.type == 'showMsg') {
                        this.msg = msg.info
                    }
                });
                this.pullStatus();
            });
        },
        setupCanvas() {
            var startX, startY;
            var drawing = false;

            this.canvas.onmousedown = this.canvas.ontouchstart = (e) => {
                e.preventDefault();
                if (this.allowDraw) {
                    drawing = true;
                    var rect = this.canvas.getBoundingClientRect();
                    startX = (e.clientX || e.touches[0].clientX) - rect.left;
                    startY = (e.clientY || e.touches[0].clientY) - rect.top;
                }
            };

            this.canvas.onmousemove = this.canvas.ontouchmove = (e) => {
                e.preventDefault();
                if (this.allowDraw && drawing) {
                    var payload = { startX, startY };

                    var rect = this.canvas.getBoundingClientRect();
                    startX = (e.clientX || e.touches[0].clientX) - rect.left;
                    startY = (e.clientY || e.touches[0].clientY) - rect.top;

                    payload.endX = startX;
                    payload.endY = startY;

                    payload.color = this.myColor;
                    payload.width = this.myWidth;

                    // 向服务端发送消息
                    this.stompClient.send("/drawStep", {}, JSON.stringify(payload));
                }
            };

            this.canvas.onmouseup = this.canvas.ontouchend = (e) => {
                e.preventDefault();
                if (this.allowDraw) {
                    drawing = false;
                }
            };
        },
        pullStatus() {
            var payload = { name: this.name, type: "status" }
            this.stompClient.send("/drawCommand", {}, JSON.stringify(payload));
        },
        startGame() {
            var payload = { name: this.name, type: "startGame" }
            this.stompClient.send("/drawCommand", {}, JSON.stringify(payload));
        },
        changeColor(color) {
            this.myColor = color;
        },
        changePenSize(size) {
            this.myWidth = size;
        },
        guess() {
            var payload = { name: this.name, type: "guess", word: this.text }
            this.stompClient.send("/drawCommand", {}, JSON.stringify(payload));
            this.text = ''
        },
        undo() {
            var payload = { name: this.name, type: "undo" }
            this.stompClient.send("/drawCommand", {}, JSON.stringify(payload));
        },
        clearCanvas() {
            var payload = { name: this.name, type: "clear" }
            this.stompClient.send("/drawCommand", {}, JSON.stringify(payload));
        },
        bad() {
            var payload = { name: this.name, type: "bad" }
            this.stompClient.send("/drawCommand", {}, JSON.stringify(payload));
        },
        reviewPics() {
            var div = this.$refs.review
            if (!this.showReview) {
                div.innerHTML = ''
                for (var p of this.pics) {
                    var img = `<p><img src='${p}' width='300' height='300' style='background-color:#FFFFCC;'></p>`
                    div.innerHTML += img
                }
                this.showReview = true
                document.documentElement.scrollTop = div.offsetTop
            } else {
                this.showReview = false
            }
        },
        doClear() {
            this.context.fillStyle = '#fff'
            this.context.fillRect(0, 0, 300, 300)
        },
        // 其他函数...
    },
};
</script>
  
<style scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

b {
    color: #881031;
}

#draw {
    background-color:#EEEEEE;
    display:flex;
    flex-direction:column;
    align-items:center;
}

#msg {
    width:300px;
    background-color:#FFFFCC;
}

#msg2 {
    padding-left:2px;
    background-color:#CCFFFF;
    color:#881031;
    width:300px;
    height:20px;
    line-height:20px;
    font-size:12px;
}

#tools {
    flex-direction:column;
    background-color: rgba(255,255,255,0.5);
}

.color {
    width: 30px;
    height: 30px;
    border-radius: 3px;
    margin: 1px;
    text-align: center;
    line-height: 30px;
    color: #666;
}

.pen-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border: #AAA 1px solid;
}

.pen {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #AAA;
}

.pen-selected {
    background-color: #333;
}

button {
    padding: 5px 10px;
    color: #000;
    background-color: #FFCCCC;
    border: none;
}

input {
    padding: 7px;
}

.box {
    background-color: #FFF;
    width: 300px;
    border: 1px solid #FFCCCC;
    border-top: none;
    padding: 10px;
}

#players {
    width: 300px;
    font-size: 80%;
    color: #000;
    text-align: left;
    background-color: #FFCCCC;
}

#review {
    width: 300px;
}
</style>  